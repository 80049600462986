<template>
  <section class="hero is-fullheight">
    <div class="hero-body has-text-centered">
      <div class="login">
        <h1 class="title is-1">Login</h1>
        <br />
        <form @submit.prevent="submit">
          <b-field>
            <b-field>
              <b-input
                v-model="form.name"
                name="name"
                size="is-medium"
                :rounded="true"
                placeholder="Benutzername"
                required
              />
            </b-field>
          </b-field>
          <b-field>
            <b-input
              v-model="form.password"
              size="is-medium"
              :rounded="true"
              name="password"
              type="password"
              placeholder="**********"
              required
              autcomplete="current-password"
            />
          </b-field>
          <br />
          <br />
          <b-button
            native-type="submit"
            type="is-primary"
            rounded
            size="is-medium"
            expanded
            :icon-left="$func.getIcon('login').icon"
            :icon-pack="$func.getIcon('login').pack"
          >
            Login
          </b-button>
        </form>
      </div>
    </div>
    <b-loading :is-full-page="true" :active="isLoading"></b-loading>
  </section>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";
import mapValues from "lodash/mapValues";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      isLoading: false,
      form: {
        name: null,
        password: null
      },
      returnUrl: ""
    };
  },
  created() {
    // redirect to home if already logged in
    if (localStorage.getItem("access_token") != null) {
      return this.$router.push("/");
    }
  },
  methods: {
    submit() {
      this.isLoading = true;
      const { name, password } = this.form;
      this.$store
        .dispatch(AUTH_REQUEST, { name, password })
        .then(() => {
          this.$router.push(this.$route.query.redirect || "/");
        })
        .catch(() => {
          this.$buefy.toast.open({
            message:
              "Der Login ist fehlgeschlagen, bitte überprüfe deinen Teilnehmernamen und das Passwort.",
            type: "is-danger",
            queue: false
          });
        })
        .finally(() => (this.isLoading = false));
    },
    reset() {
      this.form = mapValues(this.form, item => {
        if (item && typeof item === "object") {
          return [];
        }
        return null;
      });

      this.$buefy.snackbar.open({
        message: "Reset successfully",
        queue: false
      });
    }
  }
};
</script>

<style lang="scss">
.hero.is-fullheight .hero-body {
  align-items: inherit;
  margin: 0 auto;
}

@media screen and (min-width: 1024px) {
  .hero.is-fullheight .hero-body {
    padding-right: 14rem;
  }
}
</style>
