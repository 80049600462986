var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "hero is-fullheight" },
    [
      _c("div", { staticClass: "hero-body has-text-centered" }, [
        _c("div", { staticClass: "login" }, [
          _c("h1", { staticClass: "title is-1" }, [_vm._v("Login")]),
          _c("br"),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "b-field",
                [
                  _c(
                    "b-field",
                    [
                      _c("b-input", {
                        attrs: {
                          name: "name",
                          size: "is-medium",
                          rounded: true,
                          placeholder: "Benutzername",
                          required: ""
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: {
                      size: "is-medium",
                      rounded: true,
                      name: "password",
                      type: "password",
                      placeholder: "**********",
                      required: "",
                      autcomplete: "current-password"
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password"
                    }
                  })
                ],
                1
              ),
              _c("br"),
              _c("br"),
              _c(
                "b-button",
                {
                  attrs: {
                    "native-type": "submit",
                    type: "is-primary",
                    rounded: "",
                    size: "is-medium",
                    expanded: "",
                    "icon-left": _vm.$func.getIcon("login").icon,
                    "icon-pack": _vm.$func.getIcon("login").pack
                  }
                },
                [_vm._v(" Login ")]
              )
            ],
            1
          )
        ])
      ]),
      _c("b-loading", {
        attrs: { "is-full-page": true, active: _vm.isLoading }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }